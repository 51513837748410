import React, { memo } from 'react';
import { useMount, useUnmount } from 'react-use';
import styled from '@emotion/styled';
import IntradayTrendChart from '~/modules/trendChart';
import { useSignalrStoreValueOHLC } from '~/modules/SDK/Signalr/useSignalrStoreValueOHLC';
import { useSignalrStore } from '~/modules/SDK/Signalr/useSignalrStore';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { SymbolName } from '~/modules/SDK/Symbol/SymbolName';
import { FUTURESAI_THEME } from '~/modules/AppLayout/Colors';
import { flex } from '~/modules/AppLayout/FlexGridCss';
const Container = styled.div `
  ${flex.h.allCenter};
  border: 4px solid ${props => (props.theme ? '#55555a' : '#aaaaaa')};
  border-radius: 5px;
  font-size: 12px;
  width: 300px;
  height: 157px;
`;
const InfoArea = styled.div `
  ${flex.v.default};
  font-size: 12px;
  width: 40%;
  height: 150px;
  border-radius: 0px 4px 4px 0px;
  background-color: ${props => (props.theme ? '#333333' : '#ffffff')};
`;
const ChartArea = styled.div `
  ${flex.v.default};
  font-size: 12px;
  width: 60%;
  height: 150px;
  border-radius: 4px 0px 0px 4px;
  background-color: ${props => (props.theme ? '#333333' : '#ffffff')};
`;
const InfoTitle = styled.div `
  ${flex.inline.allCenter};
  background-color: ${props => (props.theme ? '#222222' : '#eeeeee')};
  color: ${props => (props.theme ? '#eeeeee' : '#333333')};
  height: 20px;
  border-radius: 0px 3px 0px 0px;
`;
const InfoData = styled.div `
  ${flex.v.mainCenter};
  align-items: left;
  height: 130px;
  background-color: ${props => (props.theme ? '#333333' : '#ffffff')};
  color: ${props => (props.theme ? '#eeeeee' : '#333333')};
`;
const InfoItem = styled.div `
  ${flex.h.default};
  padding: 1px 3px 3px 3px;
`;
const InfoValue = styled.div `
  color: ${props => props.color};
  padding-left: 5px;
`;
const CenterItem = styled.div `
  ${flex.inline.allCenter};
`;
const InfoAreaAi = styled.div `
  ${flex.v.default};
  font-size: 12px;
  width: 40%;
  height: 150px;
  border-radius: 0px 4px 4px 0px;
  background-color: ${props => (props.theme ? '#202D42' : '#ffffff')};
`;
const ChartAreaAi = styled.div `
  ${flex.v.default};
  font-size: 12px;
  width: 60%;
  height: 150px;
  border-radius: 4px 0px 0px 4px;
  background-color: ${props => (props.theme ? '#202D42' : '#ffffff')};
`;
const InfoTitleAi = styled.div `
  ${flex.inline.allCenter};
  background-color: ${props => (props.theme ? '#192333' : FUTURESAI_THEME)};
  color: ${props => (props.theme ? '#eeeeee' : '#eeeeee')};
  height: 20px;
  border-radius: 0px 3px 0px 0px;
`;
const InfoDataAi = styled.div `
  ${flex.v.mainCenter};
  align-items: left;
  height: 130px;
  background-color: ${props => (props.theme ? '#202D42' : '#ffffff')};
  color: ${props => (props.theme ? '#eeeeee' : '#202D42')};
`;
export const ToolTipSmallChart = memo(function ToolTipSmallChart(props) {
    const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol]);
    const quoteChanges = processQuoteToReadable(symbolInfo);
    const changeColor = quoteChanges.closeChange >= 0 ? '#fb111a' : '#01c200';
    useMount(() => {
        useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc');
    });
    useUnmount(() => {
        useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc');
    });
    const volumeValue = symbolInfo && symbolInfo?.volume > 10e8
        ? (symbolInfo?.volume / 10e7).toFixed(2) + '億'
        : symbolInfo?.volume;
    return (<Container theme={props.isDark}>
      <ChartArea theme={props.isDark}>
        <IntradayTrendChart symbol={props.symbol} ticksSize={10} ticksHeight={16} transparency={0.4} priceTicksMargin={-15} priceTicksSize={9}/>
      </ChartArea>
      <InfoArea theme={props.isDark}>
        <InfoTitle theme={props.isDark}>
          <SymbolName symbol={props.symbol}/>
          {props.symbol}
        </InfoTitle>
        <CenterItem>
          <InfoData theme={props.isDark}>
            <InfoItem>
              成交
              <InfoValue color={changeColor}>{symbolInfo?.close}</InfoValue>
            </InfoItem>
            <InfoItem>
              開盤 <InfoValue color={changeColor}>{symbolInfo?.open}</InfoValue>
            </InfoItem>
            <InfoItem>
              最高 <InfoValue color={changeColor}>{symbolInfo?.high}</InfoValue>
            </InfoItem>
            <InfoItem>
              最低 <InfoValue color={changeColor}>{symbolInfo?.low}</InfoValue>
            </InfoItem>
            <InfoItem>
              昨收 <InfoValue>{symbolInfo?.prevRef}</InfoValue>
            </InfoItem>
            <InfoItem>
              總量
              <InfoValue color={props.isDark ? '#ffff00' : '#ff9900'}>{volumeValue}</InfoValue>
            </InfoItem>
          </InfoData>
        </CenterItem>
      </InfoArea>
    </Container>);
});
export const ToolTipSmallChartAi = memo(function ToolTipSmallChartAi(props) {
    const symbolInfo = useSignalrStoreValueOHLC(state => state.value[props.symbol]);
    const quoteChanges = processQuoteToReadable(symbolInfo);
    const changeColor = quoteChanges.closeChange >= 0 ? '#fb111a' : '#01c200';
    useMount(() => {
        useSignalrStore.getState().subscribeAdd([props.symbol], 'ohlc');
    });
    useUnmount(() => {
        useSignalrStore.getState().subscribeRemove([props.symbol], 'ohlc');
    });
    const volumeValue = symbolInfo && symbolInfo?.volume > 10e8
        ? (symbolInfo?.volume / 10e7).toFixed(2) + '億'
        : symbolInfo?.volume;
    return (<Container theme={props.isDark}>
      <ChartAreaAi theme={props.isDark}>
        <IntradayTrendChart symbol={props.symbol} ticksSize={10} ticksHeight={16} transparency={0.5} priceTicksMargin={-15} priceTicksSize={9} isAiTheme={true}/>
      </ChartAreaAi>
      <InfoAreaAi theme={props.isDark}>
        <InfoTitleAi theme={props.isDark}>
          <SymbolName symbol={props.symbol}/>
          {props.symbol}
        </InfoTitleAi>
        <CenterItem>
          <InfoDataAi theme={props.isDark}>
            <InfoItem>
              成交
              <InfoValue color={changeColor}>{symbolInfo?.close}</InfoValue>
            </InfoItem>
            <InfoItem>
              開盤 <InfoValue color={changeColor}>{symbolInfo?.open}</InfoValue>
            </InfoItem>
            <InfoItem>
              最高 <InfoValue color={changeColor}>{symbolInfo?.high}</InfoValue>
            </InfoItem>
            <InfoItem>
              最低 <InfoValue color={changeColor}>{symbolInfo?.low}</InfoValue>
            </InfoItem>
            <InfoItem>
              昨收 <InfoValue>{symbolInfo?.prevRef}</InfoValue>
            </InfoItem>
            <InfoItem>
              總量
              <InfoValue color={props.isDark ? '#ffff00' : '#ff9900'}>{volumeValue}</InfoValue>
            </InfoItem>
          </InfoDataAi>
        </CenterItem>
      </InfoAreaAi>
    </Container>);
});
